<template>
  <b-col>
    <b-table-simple
      caption-top
      bordered
      class="position-relative mb-2"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="text-center">
            อนุมัติ
          </b-th>
          <b-th class="text-center">
            สถานะ
          </b-th>
          <b-th class="text-center">
            เลขที่คำขอ
          </b-th>
          <b-th class="text-center">
            เลขที่สัญญา
          </b-th>
          <b-th class="text-center">
            เลขทะเบียน
          </b-th>
          <b-th class="text-center">
            ชื่อลูกค้า
          </b-th>
          <b-th class="text-center">
            วันที่คำขอ
          </b-th>
          <b-th class="text-center">
            เลขเครื่องยนต์
          </b-th>
          <b-th class="text-center">
            พอร์ต
          </b-th>
          <b-th class="text-center">
            สหกรณ์
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="dataResult.length">
          <b-tr
            v-for="(item, index) in paginateArray(
              dataResult,
              perPage,
              currentPage
            )"
            :key="index"
          >
            <b-td class="text-center">
              <b-button
                variant="gradient-success"
                class="btn-icon rounded-circle"
                :to="{ path: `contract_approve/form/${item.connum}` }"
              >
                <feather-icon icon="CheckSquareIcon" />
              </b-button>
            </b-td>
            <b-td class="text-center">
              {{ item.status_desc }}
            </b-td>
            <b-td class="text-center">
              {{ item.genappnum }}
            </b-td>
            <b-td class="text-center">
              {{ item.connum }}
            </b-td>
            <b-td class="text-center">
              {{ item.regnum }}
            </b-td>
            <b-td class="text-center">
              {{ item.customer_name }}
            </b-td>
            <b-td class="text-center">
              {{ item.application_date }}
            </b-td>
            <b-td class="text-center">
              {{ item.chassis_number }}
            </b-td>
            <b-td class="text-center">
              {{ item.port_desc }}
            </b-td>
            <b-td class="text-center">
              {{ item.cooperate_code }}
            </b-td>
          </b-tr>
        </template>
        <template v-else>
          <b-tr>
            <b-td
              colspan="11"
              class="text-center"
            >
              <p style="margin-top:5px; margin-bottom:5px;">
                ไม่พบรายการที่ตรงกัน
              </p>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <b-row>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataResult.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <!-- <contract-maker-from
      :data-result="dataResult"
    /> -->
  </b-col>
</template>

<script>
import {
  BTableSimple, BThead, BTr, BTh, BTbody, BTd, BRow, BCol, BPagination, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import ContractApproveStoreModule from './ContractApproveStoreModule'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BRow,
    BCol,
    BPagination,
    BButton,

  },

  props: {
    dataResult: {
      type: [Object, Array],
      required: true,
    },
  },

  setup(emit) {
    const APP_STORE_MODULE_NAME = 'appContractAprrove'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, ContractApproveStoreModule)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const perPage = ref(25)
    const currentPage = ref(1)
    const passDataedit = ref([])

    // ประกาศตัวแปร Active / Inactive

    /* คำสั่งแบ่งหน้า */
    const paginateArray = (array, getPerPage, page) => array.slice((page - 1) * getPerPage, page * getPerPage)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    /* ฟังก์ชั่น edit item */
    const fncSelectItem = index => {
      emit('click-select-item', index)
    }

    const fncEditItem = data => {
      router.push({ name: 'contract-approve-form', params: { connum: data.connum } })
    }

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่น edit item */
    return {
      // ประกาศตัวแปรสำหรับเก็บค่า
      perPage,
      currentPage,
      fncEditItem,

      // ส่วนการทำงานแสดงผล UI
      paginateArray,

      fncSelectItem,
      passDataedit,
    }
  },

}
</script>

<style>

</style>
